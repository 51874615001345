import { Box } from "@mui/system";
import { Alert, Button, TextField, Typography } from "@mui/material";
import CustomDropdown from "components/UI/CustomDropdown";
import { useCallback, useEffect, useState } from "react";
import {
  addDataConnectorJob,
  getOrgUsers,
  getProjects,
} from "api/endpoints/UploadApi";
import { ascending } from "d3-array";
import { useUploaderContext } from "../context/Context";
import { setNewDocuments, stageDocuments } from "../context/action-creators";
import { PLATFORMS } from "utils/constants";
import useEventTracker from "api/hooks/useEventTracker";
import CreateFolder from "./CreateFolder";
import Preloader from "components/UI/Preloader";
import { convertToUTC, getISOString, offsetDate } from "utils/format";
import { useAppContext } from "context/Context";
import {
  CONNECTORS,
  DATA_CONNECTORS_LOOKUP,
  getRequestFilters,
} from "../utils/dataConnectors";
import { getNextJobRun } from "../utils/utils";
import { useFlags } from "launchdarkly-react-client-sdk";
import Connect, { views } from "./Connect";
import { PROJECT_ACTIONS } from "../utils/constants";
import S3ExportToggle from "../Projects/S3ExportToggle";
import StatusResponse from "./StatusResponse";
import { getProjectFolders } from "api/endpoints/AnalyticsApi";

const useStyles = () => ({
  root: {
    maxWidth: "450px",
    margin: "0 auto",
  },
  label: {
    fontSize: "14px",
    lineHeight: "15px",
    marginBottom: "10px",
    fontWeight: "400",
    color: "#AFAFAF",
  },
  formRow: {
    padding: "15px 0px",
  },
  createFolderLabels: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "4px",
  },
  createFolderBtn: {
    fontWeight: "normal",
  },
});

const requiredText = "This field is required";

const ConfigureProject = ({ onCreate, connectorConfig }) => {
  const {
    state: { dataImportConfig, dataConnector, selectedFilesForJob },
    dispatch,
  } = useUploaderContext();

  const {
    state: { timezoneOffset, user },
  } = useAppContext();

  const tracker = useEventTracker();
  const styles = useStyles();
  const flags = useFlags();

  const [teamMembers, setTeamMembers] = useState([]);
  const [projectName, setProjectName] = useState("");

  const [projectCreating, setProjectCreating] = useState(false);
  const [projectFolderId, setProjectFolderId] = useState("");
  const [projectFolders, setProjectFolders] = useState([]);
  const [orgUsers, setOrgUsers] = useState([]);
  const [foldersLoading, setFoldersLoading] = useState(false);
  const [orgUsersLoading, setOrgUsersLoading] = useState(false);
  const [creatingFolder, setCreatingFolder] = useState(false);
  const [errorObject, setErrorObject] = useState({});
  const [view, setView] = useState(null);

  // S3 export specific
  const [outputDataConnectorId, setOutputDataConnectorId] = useState("");
  const [s3Path, setS3Path] = useState("");
  const [showAmazonS3, setShowAmazonS3] = useState(false);
  const s3ConnectorConfig = DATA_CONNECTORS_LOOKUP.get(CONNECTORS.s3);

  useEffect(() => {
    tracker.track(
      "Step 3 - Configure Project",
      "create",
      {
        "Data source": connectorConfig?.displayName,
      },
      "Data Connectors / Configure Project"
    );
  }, []);

  useEffect(() => {
    const loadProjectFolders = async () => {
      setFoldersLoading(true);
      const res = await getProjectFolders();
      setFoldersLoading(false);
      if (res && res.data) {
        setProjectFolders(res.data);
      }
    };
    loadProjectFolders();
  }, [user]);

  useEffect(() => {
    const loadOrgUsers = async () => {
      setOrgUsersLoading(true);
      const res = await getOrgUsers();
      setOrgUsersLoading(false);
      setOrgUsers(
        res
          .map((d) => ({ value: d.id, label: d.username }))
          .sort((a, b) => ascending(a.label, b.label))
      );
    };
    loadOrgUsers();
  }, [user]);

  const setStagedDocuments = (docs) => {
    dispatch(stageDocuments(docs));
  };

  const setDocuments = useCallback(
    (docs) => {
      dispatch(setNewDocuments(docs));
    },
    [dispatch]
  );

  const handleCreate = async () => {
    const defaultDate = getISOString(new Date());

    const startDateTime = offsetDate(dataImportConfig.startDate || defaultDate, timezoneOffset)
    const endDateTime = offsetDate(dataImportConfig.endDate || defaultDate, timezoneOffset)

    const payload = {
      // Auth
      owner: user.id,
      organization: { id: user.organization.id },
      constellationTeamMembersWithAccess: teamMembers,

      // Data connector
      dataConnector: dataConnector?.id,
      dataConnectorSecretKey: dataConnector?.secretKey,

      // Project configuration
      dataProviderName: connectorConfig?.id,
      constellationProjectName: projectName.trim(),
      startDateTime: startDateTime,
      endDateTime: endDateTime,
      estimatedDocumentCount: dataImportConfig.estimatedDocumentCount,
      topic: projectFolderId,

      // Data connector specific config.
      // TODO: make it dynamic
      dataProviderProjectId: dataImportConfig.dataProviderProjectId,
      dataProviderTopicId: dataImportConfig.dataProviderTopicId,
      dataProviderTopicName: dataImportConfig.dataProviderTopicName,
      selectedPlatform: dataImportConfig.selectedPlatform,
      searchQuery: dataImportConfig.searchQuery,

      // Only for file upload
      projectFiles: [null],

      // "filters" property for the EASL queries.
      filters: getRequestFilters(
        connectorConfig,
        dataImportConfig,
        timezoneOffset
      ),

      // S3 import location
      importLocation: {},

      // will create exportDataConnector based on this
      exportDataConnectors: [],

      mockConnectors: dataImportConfig.mockConnectors,
    };

    // Store errors
    const errorObj = {};

    // Clear error object
    setErrorObject({});

    if (connectorConfig?.key === CONNECTORS.fileupload) {
      payload.projectFiles = selectedFilesForJob.map((d) => d.filename);
      payload.selectedPlatform = [
        PLATFORMS.twitter,
        PLATFORMS.reddit,
        PLATFORMS.news,
      ];
      setStagedDocuments([]);
      setDocuments([]);
    }

    if (connectorConfig?.key === CONNECTORS.s3) {
      payload.importLocation = {
        path: dataConnector.s3Path,
      };
    }

    if (showAmazonS3 && flags.s3Output) {
      if (!s3Path) {
        errorObj.s3Path = true;
      }
      if (!outputDataConnectorId) {
        errorObj.dataConnectorId = true;
      }

      payload.exportDataConnectors = [
        {
          exportLocation: {
            path: s3Path,
          },
          dataConnector: outputDataConnectorId,
          owner: user.id,
        },
      ];
    }

    // Auto updates
    if (connectorConfig?.supportsAutoUpdate && dataImportConfig.scheduled) {
      payload.jobSchedule = {
        enabled: true,
        env: process.env.REACT_APP_SCHEDULER_ENV || "development",
        schedule: convertToUTC(dataImportConfig.scheduleConfig),
      };
      payload.nextJobRun = getNextJobRun(dataImportConfig.scheduleConfig);
    }

    [
      "constellationProjectName",
      "topic",
      "constellationTeamMembersWithAccess",
    ].forEach((key) => {
      if (
        payload[key] === "" ||
        payload[key] === null ||
        payload[key]?.length === 0
      ) {
        errorObj[key] = true;
      }
    });

    // If in the middle of creating a project folder, prevent project creation
    if (creatingFolder) {
      errorObj.folderError = true;
    }

    // At this point, all fields should be filled
    if (Object.keys(errorObj).length) {
      return setErrorObject(errorObj);
    }

    // Prevent duplicate project names
    try {
      setProjectCreating(true);

      // Check if project name already exists
      const resp = await getProjects({
        projectName: payload.constellationProjectName,
      });

      if (resp.projects.length) {
        throw new Error(
          "The project name you entered is already in use. Please choose a different name."
        );
      }
    } catch (e) {
      setProjectCreating(false);
      errorObj.generalError = e.message;
      // Prevent project creation
      return setErrorObject(errorObj);
    }

    try {
      setProjectCreating(true);

      await addDataConnectorJob(payload);

      onCreate();
    } catch (e) {
      const errorData = e?.response?.data;
      const errorMessage = errorData?.error_msg;

      const response = {
        ...(errorData?.dataConnectorStatus || {}),
        error_msg: errorMessage || e.message,
      };

      setErrorObject((err) => ({
        ...err,
        dataConnectorStatus: response,
      }));
    } finally {
      setProjectCreating(false);
    }
  };

  const handleCreateFolder = ({ id, attributes: { name } }) => {
    const folders = [...projectFolders];
    folders.push({ value: id, label: name });
    setErrorObject((val) => ({ ...val, folderError: null }));
    setCreatingFolder(false);
    setProjectFolders(folders);
    setProjectFolderId(id);
  };

  const handleFolderCancel = () => {
    setCreatingFolder(false);
    setErrorObject((val) => ({ ...val, folderError: null }));
  };

  const s3Toggle = flags.s3Output && (
    <Box sx={styles.formRow}>
      <S3ExportToggle checked={showAmazonS3} onChange={setShowAmazonS3} />
    </Box>
  );

  const s3Connection = showAmazonS3 && s3ConnectorConfig && flags.s3Output && (
    <Connect
      connectorConfig={s3ConnectorConfig}
      value={{
        dataConnectorId: outputDataConnectorId,
        s3Path: s3Path,
      }}
      onChange={({ dataConnectorId, s3Path, view }) => {
        if (dataConnectorId !== undefined) {
          setOutputDataConnectorId(dataConnectorId);
        }
        if (s3Path !== undefined) {
          setS3Path(s3Path);
        }
        if (view !== undefined) {
          setView(view);
        }
      }}
      operationMode={PROJECT_ACTIONS.export}
      errorObject={errorObject || {}}
    />
  );

  return (
    <Box sx={styles.root}>
      <Typography
        sx={{
          fontSize: "20px",
          lineHeight: "25px",
          color: "#fff",
          textAlign: "center",
          my: 3,
        }}
      >
        Configure Processing Details
      </Typography>

      <Box sx={styles.formRow}>
        <Typography sx={styles.label}>Name your project</Typography>
        <TextField
          className="Elevation-0"
          id="NameProject"
          required={true}
          variant="outlined"
          fullWidth
          size="medium"
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
          error={errorObject.constellationProjectName}
          helperText={errorObject.constellationProjectName ? requiredText : ""}
        />
      </Box>

      <Box sx={styles.formRow}>
        {!creatingFolder && (
          <Box sx={styles.createFolderLabels}>
            <Typography
              sx={{ ...styles.label, marginBottom: "0px !important" }}
            >
              Choose a project folder
            </Typography>

            <Button
              data-testid="configure-project-create-folder-button"
              sx={styles.createFolderBtn}
              type="text"
              size="small"
              onClick={() => setCreatingFolder(true)}
            >
              Create a folder
            </Button>
          </Box>
        )}

        {!creatingFolder && (
          <CustomDropdown
            id="ProjectFolderDropdown"
            loading={foldersLoading}
            placeholder={"- Choose project folder -"}
            value={projectFolderId}
            onChange={(e) => setProjectFolderId(e.target.value)}
            options={projectFolders}
            error={errorObject.topic}
            helperText={requiredText}
            searchEnabled
          />
        )}

        {creatingFolder && (
          <CreateFolder
            onCancel={handleFolderCancel}
            onCreate={handleCreateFolder}
            error={errorObject.folderError}
          />
        )}
      </Box>

      <Box sx={styles.formRow}>
        <Typography sx={styles.label}>Add team members</Typography>
        <CustomDropdown
          id="AddTeamMemberButton"
          loading={orgUsersLoading}
          placeholder={"- Choose team members -"}
          searchEnabled={true}
          multiple
          value={teamMembers}
          onChange={(e) => setTeamMembers(e.target.value)}
          options={orgUsers}
          error={errorObject.constellationTeamMembersWithAccess}
          helperText={requiredText}
        />
      </Box>

      {s3Toggle}

      {s3Connection}

      {errorObject.generalError && (
        <Alert
          severity="error"
          sx={{ mt: 2, mb: 2 }}
          id="ProjectCreatedMessage"
        >
          {errorObject.generalError}
        </Alert>
      )}

      {errorObject.dataConnectorStatus && (
        <Box sx={{ mb: 2 }}>
          <StatusResponse {...errorObject.dataConnectorStatus} />
        </Box>
      )}

      <Box sx={{ textAlign: "center", my: 3 }}>
        {view !== views.create_new_config && (
          <Button
            variant="contained"
            sx={{ minWidth: 180 }}
            onClick={handleCreate}
            disabled={projectCreating}
            id="CreateProjectButton"
            data-testid="configure-project-create-project-button"
          >
            {projectCreating ? (
              <Box sx={{ marginTop: "3px" }}>
                <Preloader size={18} color={"inherit"} />
              </Box>
            ) : (
              "Create project"
            )}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ConfigureProject;
