import axios from "axios";
import { setupCache } from "axios-cache-adapter";

import { handleError } from "api/errors/errors";
import { addRefreshTokenInterceptor } from "../interceptors/refreshTokenInterceptor";
import { JWT_TOKEN_FIELD } from "utils/constants";

export const analyticsApi =
  process.env.REACT_APP_ANALYTIC_API || "/analyticapi";

const buildCacheInstance = () => {
  // Read more: https://github.com/lisaogren/axios-cache-adapter/blob/master/README.md
  const cache = setupCache({
    maxAge: 60 * 1000,
    exclude: {
      methods: ["patch", "put", "delete"],
    },
    // Invalidate only when a specific option is passed through config
    invalidate: async (config, request) => {
      if (request.clearCacheEntry) {
        await config.store.removeItem(config.uuid);
      }
    },
  });
  return cache;
};

export const buildApiInstance = (enableCaching) => {
  const cache = enableCaching ? buildCacheInstance() : undefined;

  // Create `axios` instance passing the newly created `cache.adapter`
  const api = axios.create({
    adapter: cache?.adapter,
    baseURL: analyticsApi,
    timeout: 600000,
  });

  api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem(JWT_TOKEN_FIELD);
      const auth = token ? `Bearer ${token}` : "";
      config.headers.common["Authorization"] = auth;
      return config;
    },
    (error) => Promise.reject(error)
  );

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      // show error toaster
      handleError(error);
      return Promise.reject(error);
    }
  );

  return api;
};

const api = buildApiInstance(true);
addRefreshTokenInterceptor(api);

export default api;
