import axios from "axios";
import { jwtDecode } from "jwt-decode";
import * as qs from "qs";
import { JWT_REFRESH_TOKEN_FIELD, JWT_TOKEN_FIELD } from "utils/constants";
import { isUserSSO } from "utils/sso";

const REACT_APP_COGNITO_DOMAIN = process.env.REACT_APP_COGNITO_DOMAIN || 'https://blackbird-ai-staging.auth.us-west-2.amazoncognito.com';
const REACT_APP_SSO_CLIENT_ID = process.env.REACT_APP_SSO_CLIENT_ID || '1chm6taqqs0ok496ep85m9vipe';

/**
 * This function adds a refresh token interceptor to the axios instance
 * It is used to prevent the token from expiring during the course of a user's session
 */
export const addRefreshTokenInterceptor = (axiosInstance) => {
  axiosInstance.interceptors.response.use(
    response => response,
    async error => {
      const originalRequest = error.config;
      // Check if the error is a 401 (Unauthorized) and if the request has not been retried
      if (isUserSSO() && error?.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const accessToken = await refreshToken();
        originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
        return axiosInstance(originalRequest);
      }
      return Promise.reject(error);
    }
  );
};

/**
 * This function refreshes the token
 * It is used to prevent the token from expiring during the course of a user's session
 */
const refreshToken = async () => {
    try {
        const refreshToken = localStorage.getItem(JWT_REFRESH_TOKEN_FIELD);
        if(!refreshToken) {
            console.error('No refresh token found');
            return Promise.reject(new Error('No refresh token found'));
        }
        console.log('Refreshing token');
        const response = await axios.post(`${REACT_APP_COGNITO_DOMAIN}/oauth2/token`, qs.stringify({
            'grant_type': 'refresh_token',
            'client_id': REACT_APP_SSO_CLIENT_ID,
            'refresh_token': refreshToken,
            'scope': 'openid profile email' // Ensure offline_access is included
        }), {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        });
        console.log('Response:', response.data);
        const { access_token, refresh_token: newRefreshToken } = response.data;
        localStorage.setItem(JWT_TOKEN_FIELD, access_token);
        if (newRefreshToken) {
            localStorage.setItem(JWT_REFRESH_TOKEN_FIELD, newRefreshToken);
            localStorage.setItem('jwtRefreshTokenUser', newRefreshToken);
        }
        console.log('Token refreshed');
        return access_token;
    } catch (refreshError) {
        console.error('Token refresh failed:', refreshError);
        localStorage.removeItem(JWT_TOKEN_FIELD);
        localStorage.removeItem(JWT_REFRESH_TOKEN_FIELD);
        window.location.href = '/login';
        return Promise.reject(refreshError);
    }
};

/**
 * This function schedules a token refresh 1 minute before the token expires
 * It is used to prevent the token from expiring during the course of a user's session
 */
export const scheduleTokenRefresh = () => {
  const token = localStorage.getItem(JWT_TOKEN_FIELD);
  if (!token) return;

  const { exp } = jwtDecode(token);
  const currentTime = Date.now() / 1000;
  const delay = (exp - currentTime - 60) * 1000; // Refresh 1 minute before expiry

  // If the token is not expired, schedule a refresh
  if (delay > 0) {
    setTimeout(async () => {
      try {
        await refreshToken();
        scheduleTokenRefresh(); // Schedule the next refresh
      } catch (error) {
        console.error('Token refresh failed:', error);
        // Handle token refresh failure (e.g., redirect to login)
      }
    }, delay);
  }
};