import { useEffect } from "react";
import ScheduleIcon from "@mui/icons-material/Schedule";
import SimpleTooltip from "components/UI/SimpleTooltip";
import useStateRef from "react-usestateref";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import Box from "@mui/material/Box";

momentDurationFormatSetup(moment);

const getRemainingTime = (nextJobRun) => {
  const diffInMs = new Date(nextJobRun).getTime() - new Date().getTime();
  // Get the higher bound of the delta to account for the server time difference.
  const diffInSeconds = Math.ceil(diffInMs / 1000);
  return diffInSeconds;
};

const getRemainingTimeFormat = (timeInSec) => {
  if (timeInSec < 60) return "s [sec]"; // Less than a minute.
  if (timeInSec < 3600) return "m [min]"; // Less than an hour.
  if (timeInSec < 24 * 3600) return "h [hrs], m [min]"; // Less than a day.
  return "d [days], h [hrs]"; // Greater than a day.
};

export const getRemainingTimeFormatted = (timeInSec) => {
  const formatString = getRemainingTimeFormat(timeInSec);
  const timeFormatted = moment
    .duration(timeInSec, "seconds")
    .format(formatString);
  return timeFormatted;
};

const getTimeDisplayValue = (timeInSec) => {
  if (timeInSec > 60) return getRemainingTimeFormatted(timeInSec);
  if (timeInSec > -60) return "< 1 min";
  return "Status Error";
};

export default function ScheduleCountdown({ nextJobRun, config }) {
  // Times are stored in seconds.
  const [timeLeftInSec, setTimeLeftInSec, timeLeftInSecRef] = useStateRef(
    getRemainingTime(nextJobRun)
  );

  useEffect(() => {
    let timeoutId = null;

    const tick = () => {
      const time = timeLeftInSecRef.current - 1;
      // It keeps counting upto 1 minute after the expected execution.
      if (time > -60) {
        setTimeLeftInSec(time);
        timeoutId = setTimeout(tick, 1000);
      } else {
        timeoutId && clearTimeout(timeoutId);
      }
    };

    tick();

    return () => clearTimeout(timeoutId);
  }, [setTimeLeftInSec, timeLeftInSecRef, nextJobRun]);

  const remainingTime = getTimeDisplayValue(timeLeftInSec);
  const nextRunDate = new Date(nextJobRun);
  const timeTooltip = `Next ${
    config.frequency
  } run: ${nextRunDate.toDateString()} - ${nextRunDate.toTimeString()}`;
  return (
    <SimpleTooltip text={timeTooltip} maxwidth={320}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ScheduleIcon
            fontSize="small"
            sx={{ color: "#F7B916", width: "14px", height: "14px" }}
          />
        </Box>
        <Box sx={{ marginLeft: "8px" }}>{remainingTime}</Box>
      </Box>
    </SimpleTooltip>
  );
}
