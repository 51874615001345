import {
  Box,
  Drawer,
  ListItem,
  ListItemButton,
  ListItemIcon,
  useTheme,
} from "@mui/material";
import useEventTracker from "api/hooks/useEventTracker";
import { useAppContext } from "context/Context";
import useTabs from "hooks/useTabs";
import { ReactComponent as BlackbirdTextLogo } from "icons/UI/BBLogo.svg";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  ALL_PROJECT_INDEX,
  DRAWER_CLOSED_WIDTH,
  ROUTES,
  ROUTE_PATHES,
} from "utils/constants";
import { useFlags } from "launchdarkly-react-client-sdk";
import { ACTIONS } from "context/reducer";
import CustomTooltip from "components/UI/Tooltip";
import SupportPopup from "./SupportPopup";
import { getPageUrl } from "utils/navigate";
import { hasNarrativeFeed } from "../NarrativeFeed/utils";

const useStyles = ({ theme }) => ({
  root: {
    border: "none",
    position: "relative",
    width: DRAWER_CLOSED_WIDTH + "px",
    borderRadius: "0px",
  },
  drawerPaper: {
    overflow: "auto",
    border: "none",
    background: theme.palette.background.$1,
    borderRight: "1px solid " + theme.palette.border.main,
    padding: theme.spacing(1, 0),
    width: DRAWER_CLOSED_WIDTH + "px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: "0px",
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(1.5, 1),
  },
  listItem: {
    padding: theme.spacing(1, 1.5),
  },
  listItemButton: {
    padding: 0,
    borderRadius: "4px",
    color: theme.palette.text.muted,
    "&:hover": {
      color: theme.palette.text.light,
    },
    "&.Mui-selected": {
      backgroundColor: "transparent",
      color: "white !important",
    },
    "&.Mui-selected:hover": {
      backgroundColor: "transparent",
    },
  },
  listItemIcon: {
    color: "inherit",
    minWidth: "40px",
    width: "40px",
    height: "40px",
    margin: "0 auto",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  activeItem: {
    color: "white",
  },
  separation: {
    margin: theme.spacing(0.5, 1.5),
    borderTop: "1px solid " + theme.palette.border.main,
  },
});

const NavigationDrawer = () => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const { currentPath } = useTabs();
  const { projectName, narrativeId, platformId } = useParams();

  const {
    state: { project, platform, analyzeEntity, spikeDate, narrative, narratives },
    dispatch,
  } = useAppContext();

  const flags = useFlags();

  const Route_Flags = {
    [ROUTE_PATHES.report]: flags.reportGeneration,
    [ROUTE_PATHES.narrativeFeed]: hasNarrativeFeed(),
  };

  const routes = ROUTES.filter((d) => {
    const flagExists = d.path in Route_Flags;
    return flagExists ? Route_Flags[d.path] : true;
  });

  const history = useHistory();
  const tracker = useEventTracker();

  const track = (value) => {
    tracker.track("Clicked sidebar navigation", "navigation", value, "Sidebar");
  };

  const reset = () => {
    if (analyzeEntity) {
      dispatch({
        type: ACTIONS.SET_ANALYZE_ENTITY,
        payload: null,
      });
    }
    if (spikeDate) {
      dispatch({
        type: ACTIONS.SET_SPIKE_DATE,
        payload: null,
      });
    }
  };

  const getLink = (route) => {
    if (route.url) {
      return route.url;
    }

    let page = route.path;
    const narrativeIdParam = parseInt(narrativeId);
    const foundIndex = narratives.findIndex(n => n.narrativeId === narrative.narrativeId);
    // NOTE: narrativeId field is being badly used here as an index
    const narrativeIndex = !isNaN(narrativeIdParam) ? narrativeIdParam : foundIndex > -1 ? foundIndex : 0;
    const platformSelected = platformId || platform;
    const projectSelected = projectName || project?.name;

    if (page === ROUTE_PATHES.narratives) {
      if (narrativeIndex === ALL_PROJECT_INDEX) {
        page = "discover";
      } else {
        page = "refine";
      }
    }

    const link = getPageUrl(
      projectSelected,
      platformSelected,
      narrativeIndex,
      page
    );

    return link;
  };

  const handleClick = (route) => {
    return () => {
      setTimeout(() => {
        reset();
        track(route.path);
      }, 0);
    };
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        ...styles.root,
        "& .MuiDrawer-paper": styles.drawerPaper,
      }}
      PaperProps={{
        id: "SideNav",
      }}
    >
      <Box>
        <Box sx={styles.logoContainer}>
          <BlackbirdTextLogo />
        </Box>

        <Box>
          {routes.map((route, i) => {
            const Icon = route.icon;
            return (
              <Box key={i}>
                {route.divider && <Box sx={styles.separation}></Box>}
                <ListItem dense sx={styles.listItem} id={route.id}>
                  <CustomTooltip text={route.name} placement="right" arrow>
                    <ListItemButton
                      disableRipple
                      component={Link}
                      to={getLink(route)}
                      onClick={handleClick(route)}
                      sx={styles.listItemButton}
                      selected={currentPath === route.path}
                    >
                      <ListItemIcon sx={styles.listItemIcon}>
                        <Icon />
                      </ListItemIcon>
                    </ListItemButton>
                  </CustomTooltip>
                </ListItem>
              </Box>
            );
          })}
        </Box>
      </Box>

      <Box>
        <SupportPopup styles={styles} />
      </Box>
    </Drawer>
  );
};

export default NavigationDrawer;
