import axios from "axios";
import * as qs from "qs";
import { addRefreshTokenInterceptor } from "../interceptors/refreshTokenInterceptor";

const REACT_APP_COGNITO_DOMAIN = process.env.REACT_APP_COGNITO_DOMAIN || 'https://blackbird-ai-staging.auth.us-west-2.amazoncognito.com';
const REACT_APP_SSO_CLIENT_ID = process.env.REACT_APP_SSO_CLIENT_ID || '1chm6taqqs0ok496ep85m9vipe';
const REACT_APP_SSO_REDIRECT_URI = process.env.REACT_APP_SSO_REDIRECT_URI || 'https://staging.blackbirdaitools.com/sso_callback';

const axiosInstance = axios.create();
addRefreshTokenInterceptor(axiosInstance);

/**
 * Authenticates with SSO using the code from the callback
 * @param {*} code 
 * @returns 
 */
export const authenticateWithSSOCode = async (code) => {
    let data = qs.stringify({
        'grant_type': 'authorization_code',
        'client_id': REACT_APP_SSO_CLIENT_ID,
        'code': code,
        'redirect_uri': REACT_APP_SSO_REDIRECT_URI
    });

    return handleCognitoRequest(data);
};

/**
 * Refreshes the SSO access token
 * @returns 
 */
export const refreshAccessToken = async (refreshToken) => {
    let data = qs.stringify({
        'grant_type': 'refresh_token',
        'client_id': REACT_APP_SSO_CLIENT_ID,
        'refresh_token': refreshToken
    });

    return handleCognitoRequest(data);
};

const handleCognitoRequest = async (data) => {
    let config = {
        method: 'post',
        url: `${REACT_APP_COGNITO_DOMAIN}/oauth2/token`,
        headers: { 
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data : data
    };

    const res = await axiosInstance.request(config);
    return res;
}