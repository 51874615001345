import { useCallback, useState } from "react";
import { Helmet } from "react-helmet";
import { range } from "d3-array";
import { Button, Grid, Typography, Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";

import SelectFlow from "./SelectFlow";
import FileUploadFlow from "./FileUploadFlow";
import ConfigureProject from "./ConfigureProject";
import ProcessingPage from "./ProcessingPage";
import StepperNav from "./StepperNav";
import { useUploaderContext } from "../context/Context";
import { SET_DATA_IMPORT_CONFIG, SET_DATA_CONNECTOR } from "../utils/constants";
import { setNewDocuments, stageDocuments } from "../context/action-creators";
import useEventTracker from "api/hooks/useEventTracker";
import useTitle from "hooks/useTitle";
import ImportData from "./ImportData";
import { CONNECTORS } from "../utils/dataConnectors";
import ConnectorPage from "./ConnectorPage";

const CreateProject = () => {
  const title = useTitle();
  const history = useHistory();
  const tracker = useEventTracker();
  const [step, setStep] = useState(0);
  const [connectorConfig, setConnectorConfig] = useState(null);

  const {
    state: { dataConnector },
    dispatch,
  } = useUploaderContext();

  const setStagedDocuments = (docs) => {
    dispatch(stageDocuments(docs));
  };

  const setDocuments = useCallback(
    (docs) => {
      dispatch(setNewDocuments(docs));
    },
    [dispatch]
  );

  const showBackArrow =
    (step < 3 && step > 0) || (step === 0 && !!connectorConfig);
  const hideCancel = step === 3;
  const completed = range(0, step).reduce(
    (acc, i) => ((acc[i] = true), acc),
    {}
  );

  const handleBack = () => {
    if (
      step === 0 ||
      (step === 1 && connectorConfig?.key === CONNECTORS.fileupload) ||
      (step === 1 && connectorConfig?.key === CONNECTORS.s3)
    ) {
      setConnectorConfig(null);
    }

    setStep((v) => Math.max(0, v - 1));
  };

  const handleCancel = () => {
    dispatch({
      type: SET_DATA_IMPORT_CONFIG,
      payload: {},
    });
    dispatch({
      type: SET_DATA_CONNECTOR,
      payload: {},
    });

    setStagedDocuments([]);
    setDocuments([]);
    history.push("/manage/projects");
  };

  const handleSetFlow = (conf) => {
    setConnectorConfig(conf);

    dispatch({
      type: SET_DATA_IMPORT_CONFIG,
      payload: {},
    });

    dispatch({
      type: SET_DATA_CONNECTOR,
      payload: {},
    });

    if (conf?.key === CONNECTORS.fileupload || conf?.key === CONNECTORS.s3) {
      setStep(1);
    }

    tracker.track(
      "Selected data source",
      "select",
      conf?.displayName,
      "Data Connectors / Create a project / select data connector type"
    );
  };

  const handleCreate = () => {
    tracker.track(
      "Project created",
      "create",
      {
        "Data source": connectorConfig?.displayName,
      },
      "Data Connectors / Configure project"
    );

    dispatch({
      type: SET_DATA_IMPORT_CONFIG,
      payload: {},
    });

    dispatch({
      type: SET_DATA_CONNECTOR,
      payload: {},
    });

    setStep(3);
  };

  return (
    <Box>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Grid container spacing={2} sx={{ px: 4, pt: 5 }}>
        <Grid item xs={3} sx={{ textAlign: "left" }}>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "18px",
              letterSpacing: "0.1em",
              textTransform: "uppercase",
              color: "#6F7494",
            }}
          >
            Create a project
          </Typography>
          {showBackArrow && (
            <Button
              startIcon={<ArrowBackIcon />}
              sx={{ mt: 1 }}
              onClick={handleBack}
              id="BackButton"
              data-testid="create-project-back-button"
            >
              Back
            </Button>
          )}
        </Grid>
        <Grid item xs={6} sx={{ textAlign: "center" }}>
          <StepperNav activeStep={step} completed={completed} />
        </Grid>
        {!hideCancel && (
          <Grid item xs={3} sx={{ textAlign: "right" }}>
            <Button
              color="error"
              variant="outlined"
              onClick={handleCancel}
              id="CancelButton"
              data-testid="create-project-cancel-button"
            >
              Cancel
            </Button>
          </Grid>
        )}
      </Grid>
      <Box sx={{ pt: 4 }}>
        {step === 0 && !connectorConfig && (
          <SelectFlow onSelect={handleSetFlow} />
        )}

        {step === 0 &&
          connectorConfig &&
          connectorConfig?.key !== CONNECTORS.fileupload &&
          connectorConfig?.key !== CONNECTORS.s3 && (
            <ConnectorPage
              connectorConfig={connectorConfig}
              onContinue={(dc) => {
                dispatch({
                  type: SET_DATA_CONNECTOR,
                  payload: dc,
                });
                // Clear previously selected config
                dispatch({
                  type: SET_DATA_IMPORT_CONFIG,
                  payload: {},
                });
                setStep(1);
              }}
              selectedDataConnector={{
                dataConnectorId: dataConnector?.id || "",
                s3Path: "",
              }}
            />
          )}

        {step === 1 &&
          connectorConfig &&
          connectorConfig?.key === CONNECTORS.s3 && (
            <ConnectorPage
              connectorConfig={connectorConfig}
              selectedDataConnector={{
                dataConnectorId: dataConnector?.id || "",
                s3Path: dataConnector?.s3Path || "",
              }}
              onContinue={(dc) => {
                dispatch({
                  type: SET_DATA_CONNECTOR,
                  payload: dc,
                });
                setStep(2);
              }}
            />
          )}

        {step === 1 && connectorConfig?.key === CONNECTORS.fileupload && (
          <FileUploadFlow onContinue={() => setStep(2)} />
        )}

        {step === 1 &&
          connectorConfig &&
          connectorConfig?.key !== CONNECTORS.fileupload &&
          connectorConfig?.key !== CONNECTORS.s3 && (
            <ImportData
              connectorConfig={connectorConfig}
              onContinue={() => setStep(2)}
            />
          )}

        {step === 2 && connectorConfig && (
          <ConfigureProject
            connectorConfig={connectorConfig}
            onCreate={handleCreate}
          />
        )}

        {step === 3 && connectorConfig && <ProcessingPage />}
      </Box>
    </Box>
  );
};

export default CreateProject;
