import { Button, TextField, Typography, Box } from "@mui/material";
import { useState } from "react";
import Preloader from "components/UI/Preloader";
import DoneIcon from "@mui/icons-material/Done";
import { addProjectFolder } from "api/endpoints/AnalyticsApi";

const CreateFolder = ({ onCancel, onCreate, error: hasExternalError }) => {
  const [name, setName] = useState("");
  const [creating, setCreating] = useState(false);
  const [created, setCreated] = useState(false);
  const [error, setError] = useState(null);
  const errorMsg = hasExternalError
    ? `You must create your new folder before proceeding.  
  Or, cancel and choose an existing folder.`
    : null;

  const handleCreate = async () => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (name && name.trim().length) {
      setError(null);
      setCreating(true);
      setCreated(false);

      try {
        const res = await addProjectFolder({
          name,
          organizations: [user.orgId],
        });
        setCreated(true);
        setCreating(false);

        if (res && res.body) {
          setTimeout(() => {
            onCreate(res.body.data);
          }, 1000);
        } else {
          setError("Error creating a folder");
        }
      } catch (e) {
        setCreating(false);
        console.error(e);
        setError("Error creating a folder");
      }
    } else {
      setError("Specify folder name");
    }
  };

  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "4px",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "15px",
            color: "#AFAFAF",
          }}
        >
          Create a folder
        </Typography>
        <Button
          onClick={onCancel}
          sx={{ minWidth: "80px" }}
          color="darkGrey"
          size="small"
          data-testid="create-folder-cancel-button"
        >
          Cancel
        </Button>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          marginBottom: "4px",
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Name your folder"
          size="medium"
          value={name}
          onChange={(e) => setName(e.target.value)}
          error={Boolean(error || errorMsg)}
          helperText={error || errorMsg}
        />
        <Button
          data-testid="create-folder-create-button"
          variant="outlined"
          size="medium"
          sx={{
            marginLeft: "10px",
            minWidth: "80px",
          }}
          onClick={handleCreate}
        >
          {creating ? (
            <Preloader size={12} />
          ) : created ? (
            <DoneIcon />
          ) : (
            "Create"
          )}
        </Button>
      </Box>
    </Box>
  );
};

export default CreateFolder;
