import { useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";

import { useHistory, useLocation } from "react-router-dom";

import useAppLogin from "hooks/useAppLogin";
import { getSSOErrorMessage, SSO_ERROR_CODES } from "utils/ssoErrorCodes";
import { handleSSOAuthentication } from "utils/sso";
import { getUser } from "api/endpoints/AnalyticsApi";

function SSOLoginCallback() {
  const history = useHistory();
  const { set: setLoginData } = useAppLogin();

  // Get the code from the query params
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code");
  const error = queryParams.get("error");

  const hasRun = useRef(false); // Ref to track if the useEffect has run to avoid multiple calls

  useEffect(() => {
    if(code && !hasRun.current) {
      handleAuthentication(code);
      hasRun.current = true;
    }
  }, [code]);
  
  // If there is an error, redirect to the login page
  useEffect(() => {
    if(error) {
      const errorToSearch = queryParams.get("error_description") || error;
      const errorMessage = getSSOErrorMessage(errorToSearch);
      history.push(`/login?error=${errorMessage}`);
    }
  }, [error]);

  const handleAuthentication = async (code) => {
    try {
      await handleSSOAuthentication(code);
      const user = await getUser();
      setLoginData(user);

      // Redirect to the dashboard
      history.push("/dashboard");
    } catch (error) {
      console.error("Error handling SSO authentication", error);
      history.push("/login?error="+SSO_ERROR_CODES.DEFAULT);
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="80vh"
    >
      <Typography variant="h5">Authentication...</Typography>
    </Box>
  );
}

export default SSOLoginCallback;